import {FC, useContext, useEffect, useState} from "react";
import classNames from "classnames/dedupe";
import {cn} from "src/helpers/bem";
import "./styles.scss";
import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from "react-router-dom";
import {ROUTES} from "src/consts/routes";
import {AuthContext} from "src/context/AuthContext";

const b = cn("login-page");

interface Props {
  className?: string;
}

export const LoginPage: FC<Props> = (props) => {
  const {className} = props;
  const navigate = useNavigate();
  const { login } = useContext<any>(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function handleLogin() {
    const res = await login({
      email,
      password
    });

    if (res) {
      navigate(ROUTES.EMPLOYEES)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      navigate(ROUTES.EMPLOYEES);
    }
  }, []);

  return <div className={classNames(b(), className)}>
    {/*@ts-ignore*/}
    <Container component="main" maxWidth="xs" as="div">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Box component="form"
             sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Введите email"
            name="email"
            autoComplete="username email"
            autoFocus
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Введите пароль"
            type="password"
            id="password"
            autoComplete="on"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Войти
          </Button>
        </Box>
      </Box>
    </Container>
  </div>;
};
