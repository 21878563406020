import axios from "axios";

export const appURL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: appURL,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

API.interceptors.response.use(
    function (response) {

      return response.data;
    },
    async function (error) {
      console.log('RESPONSE ERROR', error)

      console.log('ERROR', error?.response?.status)

      const originalRequest = error.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refresh = localStorage.getItem('refreshToken')

        if (refresh) {
          const tokens: any = await axios.post(`${appURL}refresh`, {
            refreshToken: refresh,
          });

          console.log('tokens', tokens)

          localStorage.setItem('accessToken', tokens?.data.accessToken);
          localStorage.setItem('refreshToken', tokens?.data.refreshToken);

          return API(originalRequest);
        }
      }

      if (error?.response?.status === 403 || error?.response?.status === 400) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }

      // return Promise.reject(error?.response?.data);
    }
  );


API.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        // @ts-ignore
        config.headers.Authorization = "Bearer " + token;
      }
      return config;
    },
    async (error) => {
      console.log('REQUEST ERROR', error)

      if (error?.response?.status === 403 || error?.response?.status === 400) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }

      // return Promise.reject(error);
    }
  );

const endpoints = {
  login: 'login',
  refreshToken: 'refresh',
  getCandidates: 'dashboard/employees',
  getObjects: 'dashboard/facilities',
  getEmployeesFilters: "dashboard/employees/filters",
  getObjectsFilters: "dashboard/facilities/filters",
}

export { API, endpoints }