import {FC} from "react";
import classNames from "classnames/dedupe";
import {cn} from "src/helpers/bem";
import "./styles.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {OptionType} from "src/types/OptionType";

const b = cn("app-select");

interface Props {
  className?: string;
  label?: string;
  value: any;
  options: OptionType[];
  onChange: any;
}

export const AppSelect: FC<Props> = (props) => {
  const { className, label, value, onChange, options } = props;

  return<FormControl size="small" className={classNames(b(), className)}>
    {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label={label}
      onChange={onChange}
    >
      {options.map((it) => <MenuItem value={it.key}>{it.value}</MenuItem>)}
    </Select>
  </FormControl>
};
