import {FC} from "react";
import classNames from "classnames/dedupe";
import {cn} from "src/helpers/bem";
import "./styles.scss";
import { Autocomplete, TextField } from "@mui/material";

const b = cn("app-autocomplete");

interface Props {
  className?: string;
  label?: string;
  value: any;
  options: any[];
  onChange: any;
}

export const AppAutocomplete: FC<Props> = (props) => {
  const { className, label, value, onChange, options } = props;

  return <Autocomplete
      size="small"
      className={classNames(b(), className)}
      id="controllable-states-demo"
      renderInput={(params) => <TextField {...params} size="small" label={label} />}
      value={value}
      onChange={onChange}
      options={options}
    />;
};
