import {FC, useEffect, useState} from "react";
import classNames from "classnames/dedupe";
import {cn} from "src/helpers/bem";
import "./styles.scss";
import {
  Autocomplete,
  Chip,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "src/consts/routes";
import {API, endpoints} from "src/api/api";
import {AppAutocomplete, AppHeader, AppSelect} from "src/components";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {DataGrid, GridColDef} from "@mui/x-data-grid";

const b = cn("employees-page");

interface Props {
  className?: string;
}

const columns: GridColDef[] = [
  { field: 'fullName', headerName: 'ФИО', width: 250},
  { field: 'phone', headerName: 'Телефон', width: 150 },
  {
    field: 'status',
    headerName: 'Статус',
    width: 120,
    renderCell: (params) => <Chip label={params.value} color="success" variant="outlined" size="small" />
  },
  { field: 'home_city', headerName: 'Город', width: 150},
  { field: 'position', headerName: 'Должность', width: 300},
  { field: 'facility', headerName: 'Объект', width: 250},
  { field: 'director', headerName: 'Оп. Директор', width: 250},
  { field: 'support_manager', headerName: 'Менеджер сопровождения', width: 250},
  { field: 'coordinator', headerName: 'Координатор', width: 250},
  { field: 'lastLoginCity', headerName: 'Город последнего захода', width: 200},
  { field: 'lastLoginAt', headerName: 'Дата захода', width: 150},
];

export const EmployeesPage: FC<Props> = (props) => {
  const {className} = props;
  const navigate = useNavigate();

  const [dictionaries, setDictionaries] = useState<any>(null)

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("accessToken");

        if (!token) {
          navigate(ROUTES.LOGIN)
        }

        const res = await API.get(endpoints.getEmployeesFilters);

        setDictionaries(res);
      } catch (e: any) {
        if (e?.response?.status === 403) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');

          navigate(ROUTES.LOGIN);
        }
      }
    })()
  }, [])

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [pagination, setPagination] = useState<any>();
  const [candidates, setCandidates] = useState<any>([]);

  const [search, setSearch] = useState('');
  const [city, setCity] = useState<any>('');
  const [status, setStatus] = useState('');

  const [area, setArea] = useState('');
  const [region, setRegion] = useState('');
  const [metro, setMetro] = useState('');
  const [district, setDistrict] = useState('');

  const [position, setPosition] = useState<any>(null);
  const [object, setObject] = useState<any>(null);
  const [director, setDirector] = useState<any>(null);
  const [coordinator, setCoordinator] = useState<any>(null);
  const [manager, setManager] = useState<any>(null);

  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [isFilters, setIsFilters] = useState(false);

  async function getCandidates() {
    try {
      let offset = paginationModel.page ? `?offset=${paginationModel.page * 10}` : '?offset=0';

      const query = getCurrentQuery();

      const res: any = await API.get(`${endpoints.getCandidates}${offset}${query}`);

      const formattedData = res?.result?.map((it: any) => {
        return {
          userID: it.employeeID || it.userID || it.individualID || it.candidateID ,
          status: it.state || '-',
          lastLoginCity: it.lastLoginCity || "-",
          lastLoginAt: it.lastLoginDate || "-",
          home_city: it.home_city || "-",
          fullName: it.fullName || "Имя не указано",
          phone: it.phone || "-",
          position: it.position || '-',
          facility: it.facility || "-",
          director: it.director || "-",
          coordinator: it.coordinator || "-",
          support_manager: it.support_manager || "-"
        };
      })

      setCandidates(formattedData || [])
      setPagination(res?.pagination);
    } catch (e: any) {
      if (e?.response?.status === 403) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        navigate(ROUTES.LOGIN);
      }
    }
  }

  function getCurrentQuery() {
    const query = search ? `&q=${search}` : '';
    const queryCoordinator = coordinator ? `&coordinator=${coordinator?.key}` : '';
    const queryDirector = director ? `&director=${director?.key}` : '';
    const queryObject = object ? `&facility=${object?.key}` : '';
    const queryPosition = position ? `&position=${position?.key}` : '';
    const queryManager = manager ? `&support_manager=${manager?.key}` : '';

    return `${query}${queryCoordinator}${queryDirector}${queryObject}${queryPosition}${queryManager}`;
  }

  function clearFilters() {
    setDateFrom(null);
    setDateTo(null);
    setSearch('');
    setCity('');
    setArea('');
    setDistrict('');
    setMetro('');
    setRegion('');
    setStatus('');
    setPosition(null);
    setObject(null);
    setDirector(null);
    setManager(null);
    setCoordinator(null);
  }

  useEffect(() => {
    if (city || status || position || object || director || coordinator || search || dateFrom || dateTo || manager || area || district || region || metro) {
      setIsFilters(true);
    } else {
      setIsFilters(false);
    }
  }, [city, status, position, object, director, coordinator, search, dateFrom, dateTo, manager, area, district, region, metro]);

  useEffect(() => {
    getCandidates();
  }, [paginationModel, search, dateFrom, dateTo, coordinator, director, object, position, manager])

  return <div className={classNames(b(), className)}>
    <AppHeader />

    <Container maxWidth="xl" sx={{paddingTop: '48px'}}>
      <div className={b('buttons')}>
        <OutlinedInput placeholder="Поиск..." size='small' sx={{width: '300px', mr: 1}} value={search} onChange={(e) => {
          setPaginationModel({page: 0, pageSize: 10})
          setSearch(e.target.value)
        }} />
        <IconButton aria-label="delete" onClick={() => setFiltersOpen((prev) => !prev)}>
          <TuneIcon />
        </IconButton>
        {isFilters && <IconButton aria-label="delete" onClick={() => clearFilters()}>
            <HighlightOffIcon/>
        </IconButton>}
      </div>

      {filtersOpen && <div className={b('filters')}>

          <div className={b('filters-row')}>
              <AppSelect
                  value={city}
                  label="Город"
                  options={[
                    {key: 'Москва', value: 'Москва'},
                    {key: 'Санкт-Петербург', value: 'Санкт-Петербург'},
                    {key: 'Екатеринбург', value: 'Екатеринбург'},
                    {key: 'Сочи', value: 'Сочи'},
                    {key: 'Ростов', value: 'Ростов'},
                    {key: 'Мурманск', value: 'Мурманск'},
                  ]}
                  onChange={(event: any) => setCity(event.target.value)}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      label="от"
                      slotProps={{textField: {size: 'small'}}}
                      value={dateFrom}
                      onChange={(e) => {
                        setPaginationModel({page: 0, pageSize: 10})
                        setDateFrom(e)
                      }}
                  />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      label="до"
                      slotProps={{textField: {size: 'small'}}}
                      value={dateTo}
                      onChange={(e) => {
                        setPaginationModel({page: 0, pageSize: 10})
                        setDateTo(e)
                      }}
                  />
              </LocalizationProvider>

              <FormControl size="small">
                  <InputLabel id="demo-simple-select-label">Статус</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Статс"
                      onChange={(event) => setStatus(event.target.value)}
                  >
                      <MenuItem value={1}>Активен</MenuItem>
                      <MenuItem value={2}>Отклонен</MenuItem>
                  </Select>
              </FormControl>

              <AppAutocomplete
                  value={position}
                  label="Должность"
                  options={dictionaries?.positions.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setPosition(newValue);
                  }}
              />
          </div>

          <div className={b('filters-row')}>

              <AppSelect
                  value={area}
                  label="Область"
                  options={[
                    {key: 'Область 1', value: 'Область 1'},
                    {key: 'Область 2', value: 'Область 2'},
                  ]}
                  onChange={(event: any) => setArea(event.target.value)}
              />

              <AppSelect
                  value={region}
                  label="Регион"
                  options={[
                    {key: 'Регион 1', value: 'Регион 1'},
                    {key: 'Регион 2', value: 'Регион 2'},
                  ]}
                  onChange={(event: any) => setRegion(event.target.value)}
              />

              <AppSelect
                  value={district}
                  label="Федеральный округ"
                  options={[
                    {key: 'Федеральный округ 1', value: 'Федеральный округ 1'},
                    {key: 'Федеральный округ 2', value: 'Федеральный округ 2'},
                  ]}
                  onChange={(event: any) => setDistrict(event.target.value)}
              />

              <AppSelect
                  value={metro}
                  label="Метро"
                  options={[
                    {key: 'Метро 1', value: 'Метро 1'},
                    {key: 'Метро 2', value: 'Метро 2'},
                  ]}
                  onChange={(event: any) => setMetro(event.target.value)}
              />

          </div>

          <div className={b('filters-row')}>
              <Autocomplete
                  value={object}
                  size="small"
                  id="controllable-states-demo"
                  renderInput={(params) => <TextField {...params} size="small" label="Объект" />}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setObject(newValue);
                  }}
                  options={dictionaries?.facilities.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
              />

              <Autocomplete
                  value={director}
                  size="small"
                  id="controllable-states-demo"
                  renderInput={(params) => <TextField {...params} size="small" label="Оп. Директор" />}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setDirector(newValue);
                  }}
                  options={dictionaries?.directors.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
              />

              <Autocomplete
                  value={coordinator}
                  size="small"
                  id="controllable-states-demo"
                  renderInput={(params) => <TextField {...params} size="small" label="Координатор" />}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setCoordinator(newValue);
                  }}
                  options={dictionaries?.coordinators.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
              />

              <AppAutocomplete
                  value={manager}
                  label="Менеджер"
                  options={dictionaries?.support_managers.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setManager(newValue);
                  }}
              />
          </div>

      </div>}

      <DataGrid
        columns={columns}
        getRowId={(item: any) => {
          return item.userID;
        }}
        rows={candidates}
        paginationModel={paginationModel}
        rowCount={pagination?.total || 0}
        paginationMode='server'
        onPaginationModelChange={setPaginationModel}
        //@ts-ignore
        onPageChange={(newPage: any) => {
          setPaginationModel((prev) => ({...prev, page: newPage}));
        }}
        checkboxSelection
      />
    </Container>
  </div>;
};
