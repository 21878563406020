import React from 'react';
import './App.scss';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {EmployeesPage, LoginPage, ObjectsPage} from "src/pages";
import {Provider} from "react-redux";
import {store} from "src/redux/store";
import {AuthProvider} from "src/context/AuthContext";
import {ROUTES} from "src/consts/routes";

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTES.EMPLOYEES,
    element: <EmployeesPage />,
  },
  {
    path: ROUTES.FACILITIES,
    element: <ObjectsPage />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </Provider>
  );
}

export default App;
