import {FC, useEffect, useState} from "react";
import classNames from "classnames/dedupe";
import {cn} from "src/helpers/bem";
import "./styles.scss";
import {Container, OutlinedInput} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "src/consts/routes";
import {API, endpoints} from "src/api/api";
import {AppAutocomplete, AppHeader} from "src/components";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import TuneIcon from "@mui/icons-material/Tune";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const b = cn("objects-page");

interface Props {
  className?: string;
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Название объекта', width: 250},
  { field: 'area', headerName: 'Область', width: 150 },
  { field: 'city', headerName: 'Город', width: 150 },
  { field: 'address', headerName: 'Адрес', width: 150 },
  { field: 'director', headerName: 'Директор', width: 250 },
  { field: 'support_manager', headerName: 'Менеджер сопровождения', width: 250},
  { field: 'coordinator', headerName: 'Координатор', width: 250 },
  { field: 'count', headerName: 'Количество сотрудников', width: 200 },
];

export const ObjectsPage: FC<Props> = (props) => {
  const {className} = props;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("accessToken");

        if (!token) {
          navigate(ROUTES.LOGIN)
        }

        const res = await API.get(endpoints.getEmployeesFilters);

        setDictionaries(res);
      } catch (e: any) {
        if (e?.response?.status === 403) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');

          navigate(ROUTES.LOGIN);
        }
      }

    })()
  }, [])

  const [isFilters, setIsFilters] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [region, setRegion] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const [coordinator, setCoordinator] = useState<any>(null);
  const [director, setDirector] = useState<any>(null);
  const [objects, setObjects] = useState<any[]>([]);
  const [manager, setManager] = useState<any>(null);
  const [pagination, setPagination] = useState<any>(null);
  const [dictionaries, setDictionaries] = useState<any>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  function getCurrentQuery() {
    const query = search ? `&q=${search}` : '';
    const queryCoordinator = coordinator ? `&coordinator=${coordinator?.key}` : '';
    const queryDirector = director ? `&director=${director?.key}` : '';
    const queryRegion = region ? `&region=${region?.key}` : '';
    const queryCity = city ? `&city=${city?.key}` : '';
    const queryManager = manager ? `&support_manager=${manager?.key}` : '';

    return `${query}${queryCoordinator}${queryDirector}${queryRegion}${queryCity}${queryManager}`;
  }

  function clearFilters() {
    setSearch('');
    setRegion(null);
    setCity(null);
    setCoordinator(null);
    setDirector(null);
    setManager(null);
  }

  async function getObjectsList() {
    try {
      let offset = paginationModel.page ? `?offset=${paginationModel.page * 10}` : '?offset=0';

      const query = getCurrentQuery();

      const res: any = await API.get(`${endpoints.getObjects}${offset}${query}`);

      const formattedData = res?.result?.map((it: any) => {
        return {
          id: it.id,
          area: it.area || '-',
          name: it.name || '-',
          city: it.city || '-',
          address: it.address || '-',
          director: it.director || '-',
          support_manager: it.support_manager || "-",
          coordinator: it.coordinator || '-',
          count: it.employee_count || 0,
        };
      })

      setObjects(formattedData || [])
      setPagination(res?.pagination);
    } catch (e: any) {
      if (e?.response?.status === 403) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        navigate(ROUTES.LOGIN);
      }
    }

  }

  useEffect(() => {
    if (search || region || city || coordinator || director || manager) {
      setIsFilters(true);
    } else {
      setIsFilters(false);
    }
  }, [search, region, city, coordinator, director, manager]);

  useEffect(() => {
    getObjectsList();
  }, [paginationModel, search, region, city, coordinator, director, manager])

  useEffect(() => {
    (async () => {
      try {
        const res = await API.get(endpoints.getObjectsFilters);

        setDictionaries(res);
      } catch (e: any) {
          if (e?.response?.status === 403) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            navigate(ROUTES.LOGIN);
          }
        }


    })()
  }, [])


  return <div className={classNames(b(), className)}>
    <AppHeader />

    <Container maxWidth="xl" sx={{paddingTop: '48px'}}>
      <div className={b('buttons')}>
        <OutlinedInput placeholder="Поиск..." size='small' sx={{width: '300px', mr: 1}} value={search} onChange={(e) => {
          setPaginationModel({page: 0, pageSize: 10})
          setSearch(e.target.value)
        }} />
        <IconButton aria-label="delete" onClick={() => setFiltersOpen((prev) => !prev)}>
          <TuneIcon />
        </IconButton>
        {isFilters && <IconButton aria-label="delete" onClick={() => clearFilters()}>
            <HighlightOffIcon/>
        </IconButton>}
      </div>

      {filtersOpen && <div className={b('filters')}>
          <div className={b('filters-row')}>
              <AppAutocomplete
                  value={region}
                  label="Область"
                  options={dictionaries?.regions?.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  }) || []}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setRegion(newValue);
                  }}
              />

              <AppAutocomplete
                  value={city}
                  label="Город"
                  options={dictionaries?.cities?.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  }) || []}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setCity(newValue);
                  }}
              />

              <AppAutocomplete
                  value={coordinator}
                  label="Координатор"
                  options={dictionaries?.coordinators?.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setCoordinator(newValue);
                  }}
              />

              <AppAutocomplete
                  value={director}
                  label="Директор"
                  options={dictionaries?.directors?.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setDirector(newValue);
                  }}
              />

              <AppAutocomplete
                  value={manager}
                  label="Менеджер"
                  options={dictionaries?.support_managers.map((it:any) => {
                    return {
                      ...it,
                      label: it.value
                    }
                  })}
                  onChange={(event: any, newValue: string | null) => {
                    setPaginationModel({page: 0, pageSize: 10})
                    setManager(newValue);
                  }}
              />
          </div>
      </div>}

      <DataGrid
        columns={columns}
        getRowId={(item: any) => {
          return item.id;
        }}
        rows={objects}
        checkboxSelection
        paginationModel={paginationModel}
        rowCount={pagination?.total || 0}
        paginationMode='server'
        onPaginationModelChange={setPaginationModel}
        //@ts-ignore
        onPageChange={(newPage: any) => {
          setPaginationModel((prev) => ({...prev, page: newPage}));
        }}
      />
    </Container>
  </div>;
};
