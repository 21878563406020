import {FC} from "react";
import classNames from "classnames/dedupe";
import {cn} from "src/helpers/bem";
import "src/components/AppHeader/styles.scss";
import {AppBar, Box, Button, Container, Toolbar} from "@mui/material";
import Logo from "src/assets/logo.png";
import {ROUTES} from "src/consts/routes";
import {useNavigate} from "react-router-dom";

const b = cn("app-header");

interface Props {
  className?: string;
}

const navLinks = [
  {
    name: 'Сотрудники',
    url: ROUTES.EMPLOYEES,
  },
  {
    name: 'Объекты',
    url: ROUTES.FACILITIES,
  }
]

export const AppHeader: FC<Props> = (props) => {
  const { className } = props;

  const navigate = useNavigate();

  const pathName = window.location.pathname;

  return <Box sx={{ flexGrow: 1 }} className={classNames(b(), className)}>
      <AppBar position="static" color="default" sx={{boxShadow: '0 0 1px'}}>
        <Container maxWidth="xl">
          <Toolbar style={{paddingLeft: 0, paddingRight: 0}}>
            <a href={ROUTES.EMPLOYEES} className={b('logo-wrap')}>
              <img className={b('logo')} src={Logo} alt="logo"/>
            </a>

            <div className={b('menu')}>
              {navLinks.map((item) => (
                <Button className={b('link')} key={item.name} sx={{color: '#000', opacity: item.url === pathName ? 1 : 0.6}} onClick={() => {
                  navigate(item.url);
                }}>
                  {item.name}
                </Button>
              ))}
            </div>

            <Button color="inherit" onClick={() => {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");

              navigate(ROUTES.LOGIN)
            }}>Выход</Button>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
};
