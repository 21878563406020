import { createContext, useState } from "react";
import {API, appURL} from "src/api/api";

export const AuthContext = createContext<any>({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState<any>(null)

  const login = async (userData: {email: string, password: string}) => {
    const res: any = await API.post(`${appURL}login`, userData);

    const isSuccess = !!res?.accessToken && !!res?.refreshToken

    if (isSuccess) {
      localStorage.setItem('accessToken', res?.accessToken);
      localStorage.setItem('refreshToken', res?.refreshToken);
    }

    return isSuccess;
  };


  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  return <AuthContext.Provider value={{
    token,
    setToken,
    login,
    logout,
  }}>
    {children}
  </AuthContext.Provider>
};
